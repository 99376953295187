

import EditIcon from '../../public/assets/edit_icon.svg';
import MinimizeIcon from '../../public/assets/minimize_icon.svg';
import MaximizeIcon from '../../public/assets/maximize_icon.svg';
import CrossIcon from '../../public/assets/cross_icon.svg';
import { useEffect, useState } from 'react';
import CrossIconNormal from '../../public/assets/cross.svg';
import PDFViewer from './PDFViewer';
import { apiCallWithAuth, validateEmail } from './utils';
import localStorageSlim from 'localstorage-slim';
import MenuButton from './MenuButton';
import PdfDropdown from './PdfDropdown';
import EditORShareButton from './EditORShareButton';
import Loader from './Loader';

const ResponsePDF = (props) => {

  const {
    showMaximizedPopup, 
    setShowMaximizedPopup, 
    showMinimizedPopup, 
    setShowMinimizedPopup, 
    requestId, 
    agreementOfferLink, 
    setAgreementOfferLink, 
    mergedPDFLink, 
    mergedPDFLinkFileName, 
    mergerPDFsIntoOne, 
    downloadAllFormsAsAZipFileLinksArray,
    downloadSingleMeredFileFileLinksArray,
    pdfViewerComponentFileLinksArray,
    resetExperience
  } = props

  const [showMailPopup, setShowMailPopup] = useState(false);
  const [email, setEmail] = useState('');
  const [sendingMail, setSendingMail] = useState(false);
  const [mailSent, setMailSent] = useState(false);
  const [popupErrorMessage, setPopupErrorMessage] = useState('');
  const [documentType, setDocumentType] = useState(agreementOfferLink)
  const [file, setFile] = useState(agreementOfferLink)
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [editLoading, setEditLoading] = useState(false)


  useEffect(()=>{
    if(agreementOfferLink){
      setFile(agreementOfferLink)
      setDocumentType(agreementOfferLink)
    }
  },[agreementOfferLink])

  useEffect(() => {
    // Hide or show the scrollbar based on the popup state
    toggleBodyScroll(showMaximizedPopup);

    // Cleanup function to ensure the scroll is restored when component unmounts or popup closes
    return () => {
      toggleBodyScroll(false);
    };
  }, [showMaximizedPopup]);  // Depend on showMaximizedPopup to re-run effect when it changes

  const onChangeDocumentType = (value) => {
    setDocumentType(value)
    setFile(value)
  }

  // Function to toggle body scroll
  function toggleBodyScroll(hide) {
    document.body.style.overflow = hide ? 'hidden' : 'auto';
  }

  const handleSendMail = async () => {
    if (!email || !validateEmail(email)) {
      setPopupErrorMessage('Please enter a valid email address.');
      return;
    }
    setSendingMail(true);

    try {
      await apiCallWithAuth({
        method: 'POST',
        url: '/api/sendmail',
        data: {
          email,
          summaryHtmlContent: agreementOfferLink,
          docLink: agreementOfferLink,
          requestId,
          userId: localStorageSlim.get('userId', { decrypt: true })
        },
        headers: {
          'Content-Type': 'application/json',
        }
      });
      setMailSent(true);
    } catch (error) {
      console.error('Error sending email:', error);
      setPopupErrorMessage('Failed to send email. Please try again.');
    }

    setSendingMail(false);
  };

  const handleEditAgreement = async () => {
    setEditLoading(true)
    let pdfLink = mergedPDFLink
    if(!pdfLink){
        pdfLink = await mergerPDFsIntoOne()
    }
    let jsonValue = [
      {
        downloadUrl: pdfLink
      }
    ];

    let jsonString = JSON.stringify(jsonValue);
    let encodedString = encodeURIComponent(jsonString);

    window.open(`https://www.sejda.com/pdf-editor?files=${encodedString}`);
    setEditLoading(false)
  };

  const onClickShareButton = () => {
    setShowMailPopup(true)
  };


  return (
    <div className="w-full md:w-2/3">
        
        { downloadLoading && <Loader loadingMessage="Downloading Offer Pdf..."/> }
        { editLoading && <Loader loadingMessage="Preparing offer to edit..."/> }

        { showMaximizedPopup && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50 overflow-auto">
              <div className="bg-white rounded-lg shadow-lg w-full h-full">
                  <>
                    <div className='shadow-md border-gray-500 '>
                      <div className='flex flex-row justify-between items-center px-14 py-4'>

                        <h2 className="text-2xl font-semibold text-lightColor5">Offer Agreement</h2>
                        <div className='flex flex-row gap-x-3 text-base font-medium pr-28'>
                            <EditORShareButton onClick={handleEditAgreement} buttonName='Edit document' Icon={EditIcon}/>
                            {/* <EditORShareButton onClick={onClickShareButton} buttonName='Share on email' Icon={ShareIcon} /> */}
                            <div>
                              <MenuButton 
                                mergedPDFLink={mergedPDFLink} 
                                downloadAllFormsAsAZipFileLinksArray={downloadAllFormsAsAZipFileLinksArray}
                                downloadSingleMeredFileFileLinksArray={downloadSingleMeredFileFileLinksArray}
                                mergedPDFLinkFileName={mergedPDFLinkFileName}
                                mergerPDFsIntoOne ={mergerPDFsIntoOne}
                                setLoading={setDownloadLoading}
                              />
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <MinimizeIcon 
                                className='cursor-pointer mr-5' 
                                onClick={()=>{setShowMinimizedPopup(true); setShowMaximizedPopup(false)}}
                            />
                            <CrossIcon 
                                className='cursor-pointer' 
                                onClick={()=>{setShowMaximizedPopup(false);resetExperience()}}
                            />
                        </div>
                      </div>
                    </div>

                    { downloadLoading && <Loader loadingMessage="Downloading Offer Pdf..."/> }
                    { editLoading && <Loader loadingMessage="Preparing offer to edit..."/> }

                    <div className='flex flex-row justify-center bg-lightColor6'>
                        <div className="mb-5">
                          <div className='mt-10 mb-7'>
                            <PdfDropdown 
                              value={documentType} 
                              onChange={onChangeDocumentType} 
                              options={pdfViewerComponentFileLinksArray} 
                              file={file}
                              width={220}
                              type='medium'
                            />
                          </div>
                          <PDFViewer file={file} />
                        </div>
                    </div>
                  </>
              </div>

            </div>
          )}


          { showMinimizedPopup && 
            <div className="fixed bottom-2 left-2 right-2 flex flex-row justify-center items-center z-50">
              <div className="bg-white rounded-lg shadow-lg border-2 w-11/12 px-14 py-4">
                    <div className='flex flex-row justify-between items-center'>

                        <h2 className="text-2xl font-semibold text-lightColor5">Offer Agreement</h2>
                        <div className='flex flex-row gap-x-3 text-base font-medium'>
                            <EditORShareButton onClick={handleEditAgreement} buttonName='Edit document' Icon={EditIcon}/>
                            {/* <EditORShareButton onClick={onClickShareButton} buttonName='Share on email' Icon={ShareIcon} /> */}
                            <div>
                              <MenuButton 
                                mergedPDFLink={mergedPDFLink} 
                                mergedPDFLinkFileName={mergedPDFLinkFileName}
                                mergerPDFsIntoOne={mergerPDFsIntoOne}
                                downloadAllFormsAsAZipFileLinksArray={downloadAllFormsAsAZipFileLinksArray}
                                downloadSingleMeredFileFileLinksArray={downloadSingleMeredFileFileLinksArray}
                                setLoading={setDownloadLoading}
                              />
                            </div>
                        </div>

                        <div className='flex flex-row'>
                            <MaximizeIcon 
                                className='cursor-pointer mr-5'
                                onClick={()=>{setShowMaximizedPopup(true); setShowMinimizedPopup(false)}}
                            />
                            <CrossIcon 
                                className='cursor-pointer' 
                                onClick={()=>{setShowMinimizedPopup(false);resetExperience()}}
                            />
                        </div>
                    
                    </div>
              </div>
            </div>
          }


        {showMailPopup && (
        <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-80 z-50">
          <div className="bg-white rounded-md shadow-lg w-1/3">
            {!mailSent ? (
              <>
                <div className='border-b border-gray-200 px-6 py-4'>
                  <div className='flex flex-row justify-between items-center'>

                    <h2 className="text-xl font-medium text-lightColor5 text-left">Share form on email</h2>
                    <div className='p-2'>
                      <CrossIconNormal
                        className='w-4 h-4 cursor-pointer' 
                        onClick={() => {setShowMailPopup(false); setPopupErrorMessage('')}}
                      />
                    </div>
                  </div>
                </div>

                <div className='p-6'>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder="Enter email"
                      className="w-full p-2 mb-4 border border-gray-300 rounded bg-white text-black outline-none h-12"
                    />
                    {popupErrorMessage && (
                      <div className="mb-4 text-red">
                        {popupErrorMessage}
                      </div>
                    )}
                    <div className="flex justify-end space-x-4">
                      
                      <button
                        onClick={handleSendMail}
                        disabled={sendingMail}
                        className={`bg-green text-white font-medium py-2 px-6 rounded-md ${sendingMail ? 'opacity-50 cursor-not-allowed' : ''}`}
                      >
                        {sendingMail ? (
                          <svg className="animate-spin h-5 w-5 mr-3 text-white" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                          </svg>
                        ) : (
                          'Send'
                        )}
                      </button>
                </div>

                </div>
              </>
            ) : (
              <>
                <p className="text-green font-bold mb-4">Summary sent successfully!</p>
                <button
                  onClick={() => setShowMailPopup(false)}
                  className="border-2 border-lightGray2 text-black2 font-medium py-2 px-4 rounded"
                >
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResponsePDF;
