import {
  FaceSmileIcon,
  ChartBarSquareIcon,
  CursorArrowRaysIcon,
  DevicePhoneMobileIcon,
  AdjustmentsHorizontalIcon,
  SunIcon,
} from "@heroicons/react/24/solid";

// import benefitOneImg from "../public/assets/benefitOne.svg";
import benefitOneImg from "../public/assets/benefit-one.png";

import benefitTwoImg from "../public/img/benefit-two.png";

import BenefitOneIcon from '../public/assets/benefit1.svg';
import BenefitTwoIcon from '../public/assets/benefit2.svg';
import BenefitThreeIcon from '../public/assets/benefit3.svg';
import BenefitFourIcon from '../public/assets/benefit4.svg';


// const benefitOne = {
//   title: "Highlight your benefits",
//   desc: "You can use this space to highlight your first benefit or a feature of your product. It can also contain an image or Illustration like in the example along with some bullet points.",
//   image: benefitOneImg,
//   bullets: [
//     {
//       title: "Understand your customers",
//       desc: "Then explain the first point breifly in one or two lines.",
//       icon: <FaceSmileIcon />,
//     },
//     {
//       title: "Improve acquisition",
//       desc: "Here you can add the next benefit point.",
//       icon: <ChartBarSquareIcon />,
//     },
//     {
//       title: "Drive customer retention",
//       desc: "This will be your last bullet point in this section.",
//       icon: <CursorArrowRaysIcon />,
//     },
//   ],
// };

const benefitOne = {
  title: "Why use Rebillion Offer Generator?",
  // desc: "You can use this space to highlight your first benefit or a feature of your product. It can also contain an image or Illustration like in the example along with some bullet points.",
  image: benefitOneImg,
  bullets: [
    {
      title: "Consistency & Accuracy",
      desc: "Minimize the risk of omitting important clauses or making typographical mistakes.",
      icon: <BenefitOneIcon />,
    },
    {
      title: "Time Efficiency",
      desc: "With Rebillion, you can ensure that all your agreements meet the latest legal standards.",
      icon: <BenefitTwoIcon />,
    },
    {
      title: "Streamlined Workflow",
      desc: "It simplifies the process of drafting and managing offer agreements with minimal effort.",
      icon: <BenefitThreeIcon />,
    },
    {
      title: "Cost Efficiency",
      desc: "Reduce the costs associated with legal consultations and document preparation.",
      icon: <BenefitFourIcon />,
    },
  ],
};

const benefitTwo = {
  title: "Offer more benefits here",
  desc: "You can use this same layout with a flip image to highlight your rest of the benefits of your product. It can also contain an image or Illustration as above section along with some bullet points.",
  image: benefitTwoImg,
  bullets: [
    {
      title: "Mobile Responsive Template",
      desc: "Nextly is designed as a mobile first responsive template.",
      icon: <DevicePhoneMobileIcon />,
    },
    {
      title: "Powered by Next.js & TailwindCSS",
      desc: "This template is powered by latest technologies and tools.",
      icon: <AdjustmentsHorizontalIcon />,
    },
    {
      title: "Dark & Light Mode",
      desc: "Nextly comes with a zero-config light & dark mode. ",
      icon: <SunIcon />,
    },
  ],
};


export {benefitOne, benefitTwo};
