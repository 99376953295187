
import UpIcon from '../../public/assets/upIcon.svg';
import DownIcon from '../../public/assets/downIcon.svg';
// import DocumentIcon from '../public/assets/file_icon.svg';
import { DocumentIcon } from '@heroicons/react/24/outline';
import DeleteIcon from '../../public/assets/delete_icon.svg';
import DragAndDrop from './DragAndDrop';  
import { handleFileChange, handleDragOver, handleDrop } from './utils';
import { useState } from 'react';

const MlsListing = (props) => {

  const {
    file, 
    setFile, 
    error, 
    setError, 
    title, 
    containerExpanded, 
    setContainerExpanded,
    setOnlyOneContainerExpanded
  } = props

  // const [containerExpanded, setContainerExpanded] = useState(false);

  const onDelete = () => {
      setFile(null)
      // setContainerExpanded(true)
      // setOnlyOneContainerExpanded('mlsListing')
  }

  const handleFileChangeCustom = (e) =>{
    handleFileChange(e.target.files[0], setFile, 7, setError, setContainerExpanded)
  }

  const handleDropCustom = (e) =>{
    handleDrop(e, setFile, 5, setError, setContainerExpanded)
  }

  return (
    <div className="w-full md:w-2/3">
        <div className={`border rounded-lg mt-6 border-borderColor `}>
          <div
            className={`flex justify-between items-center cursor-pointer ${containerExpanded ? ' bg-green' : ''} p-4 rounded-t-lg`}
            onClick={() =>  {setContainerExpanded(!containerExpanded);setOnlyOneContainerExpanded('mlsListing')}}
          >
            <h2 className={`font-normal text-base ${containerExpanded ? ' text-white' : 'text-lightColor4'}`}>{title}</h2>
            {containerExpanded ? <UpIcon /> : <DownIcon />}
          </div>
          {containerExpanded && (   
            <div className='flex flex-col items-center w-full'>
              <div className="w-full p-5">
                <DragAndDrop 
                  files={file} 
                  handleDragOver={handleDragOver} 
                  handleDrop={handleDropCustom} 
                  handleFileChange={handleFileChangeCustom} 
                  placeholderText="PDF (MAX. 7 pages)"
                  multiple={"false"}
                  accept="application/pdf"
                />
              </div>
            </div>
          )}
        </div>

        {file && (
          <div className="mt-2 p-4 text-base font-normal text-lightColor5 bg-lightGray flex flex-row justify-between items-center">
            <div className='flex flex-row items-center'>
                <DocumentIcon className="mr-3 h-6 w-6" />  
                {file.name && file.name.length > 50 ? file.name.substring(0, 50) + '...' : file.name}
            </div>
            <DeleteIcon className="cursor-pointer" onClick={onDelete}/>
          </div>
        )}
        {error && (
          <div className="mt-2 ml-2 text-base font-semibold text-red-500">
            {error}
          </div>
        )}
    </div>
  );
};

export default MlsListing;
