
import UpIcon from '../../public/assets/upIcon.svg';
import DownIcon from '../../public/assets/downIcon.svg';
import CrossIcon from '../../public/assets/cross.svg';
// import DocumentIcon from '../public/assets/file_icon.svg';
import { DocumentIcon } from '@heroicons/react/24/outline';
import DeleteIcon from '../../public/assets/delete_icon.svg';
import { useState } from 'react';
import SelectDropdown from './SelectSmall';
import DragAndDrop from './DragAndDrop';
import { handleDragOver } from './utils';
import options from '../../config/docsCategoryList.json';
import ContinueButton from './ContinuButton';

const AdditionalDocs = (props) => {


  const {
    files, 
    setFiles, 
    error, 
    setError, 
    title, 
    containerExpanded, 
    setContainerExpanded,
    setOnlyOneContainerExpanded
  } = props

  // const [containerExpanded, setContainerExpanded] = useState(false)
  const [showPopup, setShowPopup] = useState(false);
  const [localFiles, setLocalFiles] = useState([])

  const onDelete = (currentFile) => {
    setLocalFiles(localFiles.filter((file)=>file.name !== currentFile.name))
      // setContainerExpanded(false)
  }

   // Update an item
   const updateItem = (value, currentFile) => {
    currentFile.documentType = value
    setFiles(files.map(file => file.name === currentFile.name ? currentFile : file));
  };

  const handleFileChange = async (e) => {
    loadFiles(e.target.files)
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    await loadFiles(files)
  };

  const resetFiles = () => {
    setShowPopup(true)
    setLocalFiles(files)
    setError('')
  }

  const loadFiles = async (newFiles) => {
    const selectedFiles = Array.from(newFiles);
    selectedFiles.map(file => file.documentType = 'others')
    resetFiles()

    if (selectedFiles.length > 25) {
      setError('You can only upload up to 25 files.');
      return;
    }

    if (selectedFiles.every(file => file.type === 'application/pdf')) {
      try {
        setLocalFiles([...files, ...newFiles]);
      } catch (error) {
        setError(error.message);
        setLocalFiles([]);
      }
    } else {
      setError('Please select valid PDF files.');
    }
  }

  const onClickContinue = async () => {
    const counterOfferCategory = localFiles.filter(localFile => localFile.documentType == 'counterOffer')
    const preApprovalCategory = localFiles.filter(localFile => localFile.documentType == 'preApproval')
    const disclosureCategory = localFiles.filter(localFile => localFile.documentType == 'disclosure')
    const addendumCategory = localFiles.filter(localFile => localFile.documentType == 'addendum')
    const othersCategory = localFiles.filter(localFile => localFile.documentType == 'others')

    if (counterOfferCategory.length > 5 
      || preApprovalCategory.length > 5
      || disclosureCategory.length > 5
      || addendumCategory.length > 5
      || othersCategory.length > 5
    ){
      return setError('You can only upload 5 files from each category')
    }
    setShowPopup(false)
    setFiles(localFiles)
  };

  return (
    <div className="w-full md:w-2/3">
        <div className={`border rounded-lg mt-6 border-borderColor`}>
          <div
            className={`flex justify-between items-center cursor-pointer ${containerExpanded ? ' bg-green' : ''} p-4 rounded-t-lg`}
            onClick={() => {setContainerExpanded(!containerExpanded);setOnlyOneContainerExpanded('additionalFiles')}}
          >
            <h2 className={`font-normal text-base ${containerExpanded ? ' text-white' : 'text-lightColor4'}`}>{title}</h2>
            {containerExpanded ? <UpIcon/> : <DownIcon/>}
          </div>
          {containerExpanded && (   
            <div className='flex flex-col items-center w-full p-5'>
              <DragAndDrop 
                files={files} 
                handleDragOver={handleDragOver} 
                handleDrop={handleDrop} 
                handleFileChange={handleFileChange} 
                placeholderText="Multiple files allowed like Disclosure Summary, Pre-Approval, TISH, Offer summary etc"
                multiple={"true"}
                accept="application/pdf"
              />
            </div>
          )}
        </div>

        {files && files.slice(0,3).map(file => (
              <div key={file.name} className="mt-2 p-4 py-0 text-base font-normal text-lightColor5 bg-lightGray flex flex-row justify-between items-center">
                <div className='flex flex-row items-center'>
                    <DocumentIcon className="mr-2 h-6 w-6" />  
                    {file.name && file.name.length > 30 ? file.name.substring(0, 30) + '...' : file.name}
                </div>
                <div className='flex flex-row items-center'>
                  {/* {file.documentType} */}
                  <SelectDropdown 
                    file={file} 
                    value={file.documentType} 
                    onChange={updateItem} 
                    disabled="true"
                    options={options} 
                    width={140}
                  />
                  {/* <DeleteIcon className="cursor-pointer ml-4" onClick={()=>onDelete(file)}/> */}
                </div>
              </div>
        ))}

        {
          files.length > 0 &&
            <div 
              onClick={resetFiles} 
              className='cursor-pointer text-green font-semibold text-sm pl-2 mt-2' 
            >
              See all documents and edit tags
            </div>
        }


        { showPopup && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-90 z-50 ">
              <div className="bg-white rounded-lg shadow-md w-1/2">
                <div className='border-b border-gray-200 '>
                  <div className='flex flex-row justify-between items-center p-4'>

                    <h2 className="text-xl font-medium text-black2 text-left">Other Documents</h2>
                    <div className='p-2'>
                      <CrossIcon
                        className='cursor-pointer' 
                        onClick={() => setShowPopup(false)}
                      />
                    </div>
                  </div>
                </div>

                <br/>

                <div className='pl-4 pr-0'>
                    {
                      localFiles.length > 0 &&
                        <div className={`flex justify-between ml-2 lg:mr-44 md:mr-10`}>
                            <div>Document</div>
                            <div>Tag</div>
                        </div>
                    }
                    <div className="max-h-72 overflow-y-auto">
                        {localFiles && localFiles.map(localFile => (
                              <div key={localFile.name} className="mt-2 ml-2 text-base font-semibold text-gray-800 pr-2 py-1 flex flex-row justify-between items-center">
                                <div className='flex flex-row items-center'>
                                    <DocumentIcon className="mr-2 h-6 w-6" />  
                                    {localFile.name}
                                </div>
                                <div className='flex flex-row items-center'>
                                    <SelectDropdown 
                                      file={localFile}
                                      value={localFile.documentType} 
                                      onChange={updateItem}
                                      options={options} 
                                      width={140}
                                    />
                                    <DeleteIcon 
                                      className="cursor-pointer mx-4" 
                                      onClick={()=>{onDelete(localFile); localFile.length==1 && setShowPopup(false)}}
                                    />
                                </div>
                              </div>
                        ))}
                        {
                          localFiles && localFiles.length == 0 && !error &&
                            <div className='font-semibold text-gray-800 text-center'> No files uploaded !</div>
                        }

                      {/* {error && (
                        <div className="mt-2 ml-2 text-base font-semibold text-red-500">
                          {error}
                        </div>
                      )} */}
                      
                    </div>
                    <div className="flex justify-between space-x-4 mt-5 mx-8 my-4">
                      {(
                        <div className="mt-2 ml-2 text-base font-semibold text-red-500">
                          {error}
                        </div>
                      )}
                      <ContinueButton onClick={onClickContinue}  buttonName='Continue'/>
                    </div>
                </div>
                 
              </div>

            </div>
          )}
    </div>
  );
};

export default AdditionalDocs;
