
const ContinueButton = ({onClick, buttonName}) => {

    return (
        <button
            onClick={onClick}
            className={`bg-green text-white text-sm font-medium py-3 px-6 rounded-md`}
        >
            {buttonName}
        </button>
    )
}

export default ContinueButton