import Head from "next/head";
import Hero from "../components/hero";
import Navbar from "../components/navbar";
import SectionTitle from "../components/sectionTitle";

import { benefitOne, benefitTwo } from "../components/data";
import Video from "../components/video";
import Benefits from "../components/benefits";
import Footer from "../components/footer";
import Testimonials from "../components/testimonials";
import Cta from "../components/cta";
import Faq from "../components/faq";
import PopupWidget from "../components/popupWidget";
// import TransactionCoordinator from "../components/transactionCoordinator";
import AgreementGeneration from "../components/agreementGeneration";
import { useTheme } from "next-themes";
import { useEffect } from "react";


const Home = () => {
  const { theme, setTheme } = useTheme();
  
  useEffect(()=>{
    setTheme("light")
  },[])

  return (
    <>
      <Head>
        <title>Generate Residential Purchase Agreement</title>
        <meta
          name="description"
          content="AI-Powered Transaction Coordination Services for Real Estate Agents."
        />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Navbar />

      <SectionTitle
        // pretitle="Nextly Benefits"
        title="Write an Offer Agreement">
        Upload documents like MLS Listing, agent intake form, and other documents to generate offer agreement with our AI.
      </SectionTitle>

      <AgreementGeneration />
      {/* <PDFViewer file="https://ahaan-media-dev.s3.ap-south-1.amazonaws.com/realtor-docs/zYvzXm93MyTKs4/11PropertyInspectionwith notes_1718628842886.pdf" /> */}
      {/* <Hero /> */}
      {/* <SectionTitle
        pretitle="Nextly Benefits"
        title=" Why should you use this landing page">
        Nextly is a free landing page & marketing website template for startups
        and indie projects. Its built with Next.js & TailwindCSS. And its
        completely open-source.
      </SectionTitle> */}
      <Benefits data={benefitOne} />
      {/* <Benefits imgPos="right" data={benefitTwo} /> */}
      {/* <SectionTitle
        pretitle="Watch a video"
        title="Learn how to fullfil your needs">
        This section is to highlight a promo or demo video of your product.
        Analysts says a landing page with video has 3% more conversion rate. So,
        don&apos;t forget to add one. Just like this.
      </SectionTitle> */}
      {/* <Video /> */}
      {/* <SectionTitle
        pretitle="Testimonials"
        title="Here's what our customers said">
        Testimonails is a great way to increase the brand trust and awareness.
        Use this section to highlight your popular customers.
      </SectionTitle> */}
      {/* <Testimonials /> */}
      {/* <SectionTitle pretitle="FAQ" title="Frequently Asked Questions">
        Answer your customers possible questions here, it will increase the
        conversion rate as well as support or chat requests.
      </SectionTitle> */}
      {/* <Faq /> */}
      {/* <Cta /> */}
      <Footer />
      {/* <PopupWidget /> */}
    </>
  );
}

export default Home;
