import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function SelectLabels({statesList, value, handleChange}) {
  // const [age, setAge] = React.useState('');

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };

  return (
    <div>
      <FormControl 
        className='w-full border-borderColor'
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#79747E', // Default border color
              borderRadius: '8px'
            },
            '&:hover fieldset': {
              borderColor: '#79747E', // Hover border color
            },
            '&.Mui-focused fieldset': {
              borderColor: '#79747E', // Focused border color
            },
          },
          '& .MuiInputBase-input': {
            color: '#49454F', // Text color
          },
          '& .MuiInputLabel-root': {
            color: '#49454F', // Label text color
          },
          '& .Mui-focused .MuiInputLabel-root': {
            color: '#49454F', // Focused label text color
          },
          '& .MuiSelect-icon': {
            right: '10px', // Adjust this value to move the arrow left
          },
        }}
      >
        <InputLabel id="demo-simple-select-helper-label">Select State</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={value}
          label="Select State"
          onChange={handleChange}
        >
          {/* <MenuItem value="">
            <em>None</em>
          </MenuItem> */}
          {
            statesList.map(state=>
              <MenuItem value={state.code} key={state.code}>{state.name}</MenuItem>
            )
          }
          {/* <MenuItem value={20}>Twenty</MenuItem> */}
          {/* <MenuItem value={30}>Thirty</MenuItem> */}
        </Select>
        {/* <FormHelperText>With label + helper text</FormHelperText> */}
      </FormControl>
    </div>
  );
}