import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectVariants({file, value, onChange, disabled, options, width, type}) {

  return (
    <div>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 211 }}>
        {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={value}
        // label="Age"
          disabled={disabled == "true"}
         onChange={(e)=>onChange(e.target.value, file)}
        >
        {
          options.map(option=>(
            <MenuItem value={option.value} key={option.displayValue} className={`${type && 'py-4'}`}>
              {option.displayValue}
            </MenuItem>
          ))
        }
        </Select>
      </FormControl>
    </div>
  );
}
