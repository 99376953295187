
import UpIcon from '../../public/assets/upIcon.svg';
import DownIcon from '../../public/assets/downIcon.svg';
import EditNoteIcon from '../../public/assets/edit_note.svg';
import EditNoteBlackIcon from '../../public/assets/edit_note_black.svg';
import UploadIcon from '../../public/assets/upload.svg';
import CrossIcon from '../../public/assets/cross.svg';
import { DocumentIcon } from '@heroicons/react/24/outline';
// import DocumentIcon from '../public/assets/file_icon.svg';
import DeleteIcon from '../../public/assets/delete_icon.svg';
import { useState } from 'react';
import DragAndDrop from './DragAndDrop';
import { handleFileChange, handleDragOver, handleDrop } from './utils';
import { generatePDF } from './utils';
import ContinueButton from './ContinuButton';

const AgentIntakeForm = (props) => {


  const {
    file, 
    setFile, 
    error, 
    setError, 
    title, 
    containerExpanded, 
    setContainerExpanded,
    setOnlyOneContainerExpanded
  } = props

  // const [containerExpanded, setContainerExpanded] = useState(false);
  const [showUploadFormPopup, setShowUploadFormPopup] = useState(false);
  const [showTextBoxPopup, setShowTextBoxPopup] = useState(false);
  const [agentInputText, setAgentInputText] = useState('');
  const [agentInputTextForPopup, setAgentInputTextForPopup] = useState('');

  const onDelete = () => {
      setFile(null)
      // setContainerExpanded(true)
      // setOnlyOneContainerExpanded('agentForm')
      setAgentInputText('')
  }

  const on = () => {
      setFile(null)
      setContainerExpanded(true)
      setAgentInputText('')
  }

  const handleFileChangeCustom = (e) =>{
    handleFileChange(e.target.files[0], setFile, 7, setError, setContainerExpanded);
    setShowUploadFormPopup(false); 
    setAgentInputText('')
  }

  const handleDropCustom = (e) =>{
    handleDrop(e, setFile, 7, setError, setContainerExpanded)
    setShowUploadFormPopup(false); 
    setAgentInputText('')
  }

  const onClickButton = () => {
    try{
      if(agentInputTextForPopup.length >= 5000) return setError('You can enter 5000 characters max!')
      setError('')
      setShowTextBoxPopup(false)
      setAgentInputText(agentInputTextForPopup)
      const generatedPdfFile = generatePDF(agentInputTextForPopup)
      setFile(generatedPdfFile)
    }catch(err){
      console.error(err.message)
      setError('Failed to generate PDF !')
    }
  }

  return (
    <div className="w-full md:w-2/3">
        <div className={`border rounded-lg mt-6 border-borderColor`}>
          <div
            className={`flex justify-between items-center cursor-pointer ${containerExpanded ? ' bg-green' : ''} p-4 rounded-t-lg`}
            onClick={() =>  {setContainerExpanded(!containerExpanded);setOnlyOneContainerExpanded('agentForm')}}
          >
            <h2 className={`font-normal text-base ${containerExpanded ? ' text-white' : 'text-lightColor4'}`}>{title}</h2>
            
            {containerExpanded ? <UpIcon/> : <DownIcon/>}
          </div>
          {containerExpanded && (   
            <div className='flex flex-col items-center w-full'>
              <div
                className="w-full p-3 text-gray-800"
                onDrop={(e) => handleDrop(e, setFile, 7, setError)}
                onDragOver={handleDragOver}
              >

                <div className='mb-2 ml-2 text-sm font-normal text-gray-800'>Select any of the options</div>
                <div className='flex flex-row'>
                  <div 
                    className='flex flex-row items-center w-1/2 justify-center bg-lightGray2 py-2 mx-2 cursor-pointer font-medium text-base text-lightColor4'
                    onClick={()=>setShowUploadFormPopup(true)}
                  >
                      <UploadIcon className='mr-2'/>
                      Upload file
                  </div> 

                  <div 
                    className='flex flex-row items-center w-1/2 justify-center bg-lightGray2 py-2 mx-2 cursor-pointer font-medium text-base text-lightColor4'
                    onClick={()=>{setShowTextBoxPopup(true);setAgentInputTextForPopup(agentInputText)}}
                  >
                      <EditNoteIcon className='mr-2' />
                      Type as text
                  </div>
                </div>  
                
              </div>
            </div>
          )}
        </div>
        

        {error && (
          <div className="mt-2 ml-2 text-base font-normal text-red-500">
            {error}
          </div>
        )}

        {
          agentInputText && !showTextBoxPopup ?
            <div>
                <div className="mt-2 p-4 text-base font-normal text-lightColor5">
                  <div className='flex flex-row justify-between items-center'>
                    <div>
                        <EditNoteBlackIcon className="inline mr-2 h-6 w-6" />  
                        <span className='font-semibold'>Offer Details</span>
                    </div>
                    <DeleteIcon className="cursor-pointer" onClick={onDelete}/>
                  </div>
                  <div className='text-gray mx-8'>
                    {agentInputText.substring(0, 190)}
                    {agentInputText.length > 200 && '...'}
                  </div>
                </div>
                <span 
                  onClick={()=>{setShowTextBoxPopup(true);setAgentInputTextForPopup(agentInputText)}} 
                  className='cursor-pointer text-green ml-2 text-base font-medium'
                >
                  Edit details
                </span>
            </div>
            :
            file && !showUploadFormPopup &&
              <div>
                  <div className="mt-2 p-4 text-base font-normal text-lightColor5 bg-lightGray p-3 flex flex-row justify-between items-center">
                    <div className='flex flex-row items-center'>
                        <DocumentIcon className="mr-2 h-6 w-6" />  
                        {file.name && file.name.length > 50 ? file.name.substring(0, 50) + '...' : file.name}
                    </div>
                    <DeleteIcon className="cursor-pointer" onClick={onDelete}/>
                  </div>
                  <p className='text-green text-base font-medium cursor-pointer' onClick={()=>setShowUploadFormPopup(true)}>Re-upload document</p>
              </div>
        }

        { showUploadFormPopup && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
              <div className="bg-white rounded-lg shadow-md xl:w-2/5 md:w-1/2 sm:w-2/3">
                  <div className='border-b border-gray-200 '>
                    <div className='flex flex-row justify-between items-center p-4'>

                      <h2 className="text-lg font-medium text-gray-800 text-left">Upload your file</h2>
                      <div className='p-2'>
                        <CrossIcon
                          className='cursor-pointer' 
                          onClick={() => setShowUploadFormPopup(false)}
                        />
                      </div>
                    </div>
                  </div>

                  <br/>

                  <div className='p-4'>
                      <DragAndDrop 
                        files={file} 
                        handleDragOver={handleDragOver} 
                        handleDrop={handleDropCustom} 
                        handleFileChange={handleFileChangeCustom} 
                        // disabled={formResponse && uploadLoading && summarizeLoading} 
                        placeholderText="PDF (MAX. 7 pages)"
                        multiple={"false"}
                        accept="application/pdf"
                      />
                      <div className="flex justify-end space-x-4 mt-5">
                      </div>
                  </div>
              </div>

            </div>
          )}


          { showTextBoxPopup && 
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50">
              <div className="bg-white rounded-lg shadow-md xl:w-2/5 md:w-1/2 sm:w-2/3">
                  <div className='border-b border-gray-200'>
                    <div className='flex flex-row justify-between items-center py-4 px-8'>

                      <h2 className="text-xl font-medium text-gray-800 text-left">Offer details</h2>
                      <div className='p-2'>
                          <CrossIcon
                            className='cursor-pointer' 
                            onClick={() => {setShowTextBoxPopup(false);setError('')}}
                          />
                      </div>
                    </div>
                  </div>

                  <br/>

                  <div className='px-8 py-4 pt-0 text-gray-800'>
                      <div className="">
                        <div>
                            <p className='mb-3 font-medium text-sm text-gray-800'>Enter offer details</p>
                            <textarea 
                              name="postContent" 
                              className='w-full xl:h-80 lg:h-60 md:h-48 sm:h-28 border-2 border-lightGray3 outline-none p-2'
                              value={agentInputTextForPopup}
                              onChange={(e)=>e.target.value.length >= 5000 ? setError('You can enter 5000 characters max!') : setAgentInputTextForPopup(e.target.value)}
                              maxLength={5000}
                            />
                        </div>
                      </div>
                      <div className="flex justify-between space-x-4 mt-5">
                        {(
                          <div className="mt-2 ml-2 text-base font-semibold text-red-500">
                            {error}
                          </div>
                        )}
                        <ContinueButton onClick={onClickButton}  buttonName='Continue'/>
                    </div>
                  </div>
              </div>
            </div>
          }
    </div>
  );
};

export default AgentIntakeForm;
