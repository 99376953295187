import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import localStorageSlim from 'localstorage-slim';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import axios from "axios";
import { jsPDF } from "jspdf";

export const handleFileChange = async (file, setFile, maxPages, setError, setContainerExpanded) => {
    if (file && file.type === 'application/pdf') {
      try {
        await validatePDF(file, maxPages);
        setFile(file);
        setError('');
        setContainerExpanded(false)
      } catch (error) {
        setError(error.message);
        setFile(null);
      }
    } else {
      setError('Please select a valid PDF file.');
    }
  };

export const validatePDF = async (file, maxPages) => {
  const arrayBuffer = await file.arrayBuffer();
  const pdf = await PDFDocument.load(arrayBuffer);

  // const pdf = await PDFDocument.load(arrayBuffer, {ignoreEncryption: true});
  const totalPages = pdf.getPageCount();
  if (totalPages > maxPages) {
    throw new Error(`The document has more than ${maxPages} pages, kindly check and upload only the required information.`);
  }
};

  
export const handleDrop = async (e, setFile, maxPages, setError, setContainerExpanded) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    await handleFileChange(file, setFile, maxPages, setError, setContainerExpanded);
};

export const handleDragOver = (e) => {
    e.preventDefault();
};


export const apiCallWithAuth = async (options) => {
  try {
    const token = localStorageSlim.get('auth', { decrypt: true });
    if (!token) {
      const newAuthToken = await fetchAuthToken();
      const response = await axios({
        ...options,
        headers: {
          ...options.headers,
          'authToken': newAuthToken,
        }
      });
      return response;
    }

    const response = await axios({
      ...options,
      headers: {
        ...options.headers,
        'authToken': token,
      }
    });
    return response;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      const newAuthToken = await fetchAuthToken();
      const response = await axios({
        ...options,
        headers: {
          ...options.headers,
          'authToken': newAuthToken,
        }
      });
      return response;
    } else {
      throw error;
    }
  }
};
  
export const fetchAuthToken = async () => {
  try {
    const response = await axios.get('/api/auth');
    const authToken = response.data.authToken;
    localStorageSlim.set('auth', authToken, { encrypt: true, ttl: 3600 });
    return authToken;
  } catch (error) {
    console.error('Error getting auth token:', error);
    throw new Error('Failed to get auth token');
  }
};

export const mergePDFs = async (files) => {
  if (files.length == 0) return null;
  const mergedPdf = await PDFDocument.create();
  for (const file of files) {
    const arrayBuffer = await file.arrayBuffer();
    const pdf = await PDFDocument.load(arrayBuffer);
    const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
    copiedPages.forEach((page) => {
      mergedPdf.addPage(page);
    });
  }

  const mergedPdfFile = await mergedPdf.save();
  return new File([mergedPdfFile], 'merged.pdf', { type: 'application/pdf' });
};

export const validateEmail = (email) => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const downloadAllAsZip = async (files, mergedPDFLinkFileName) => {
  const zip = new JSZip();
  // console.log('files: ',files)

  try {
      // Loop over each file and prepare a promise to process it
      const fetchPromises = files.map(async (file) => {
          // Ensure the file.data is properly formatted as a Blob
          const blob = new Blob([file], { type: file.type });
          // Add it to the zip
          if(file.name && file.name.includes('.pdf')){
            zip.file(`${file.name}`, blob);
          }else{
            zip.file(`${file.name}.pdf`, blob);
          }
      });

      // Wait for all files to be processed and added to the zip
      await Promise.all(fetchPromises);

      // Generate the zip file as a Blob
      const content = await zip.generateAsync({ type: 'blob' });

      // Use FileSaver to save the Blob as a zip file
      saveAs(content, `${mergedPDFLinkFileName}.zip`);
  } catch (error) {
      console.error('Failed to download PDFs:', error);
  }
};

export function generatePDF(text) {
  const doc = new jsPDF();

  // Define margins and page size
  const marginLeft = 10;
  const marginRight = 10;
  const pageHeight = doc.internal.pageSize.height;
  const pageWidth = doc.internal.pageSize.width;
  
  // Define the line height for the breaks
  const lineHeight = 10;

  // Maximum width of the text lines
  const maxWidth = pageWidth - marginLeft - marginRight;

  // Split the text into lines that fit into the page width
  const lines = doc.splitTextToSize(text, maxWidth);

  let yPosition = 20; // Initial vertical position for the first line

  lines.forEach(line => {
    if (yPosition + lineHeight > pageHeight - 20) { // Check if the line fits on the current page
      doc.addPage(); // Add a new page
      yPosition = 20; // Reset y position for the new page
    }

    // Add text line at the calculated position
    doc.text(line, marginLeft, yPosition);
    yPosition += lineHeight; // Move to the next line position
  });

  // Save the PDF or output it in a specific way
  // doc.save('generated.pdf'); // Automatically downloads the PDF

  // Convert the document to a byte array
  const pdfBytes = doc.output('arraybuffer');

  // Return a Blob instead of downloading
  // return new Blob([pdfBytes], { type: 'application/pdf' });

  // Convert the binary blob to a File object
  return new File([pdfBytes], 'agentForm.pdf', { type: 'application/pdf' });
}


export const downloadPdf = async (pdfUrl, fileName) => {
  try {
      // console.log('pdfUrl: ',pdfUrl)
      // console.log('fileName: ',fileName)
      const response = await fetch(pdfUrl);
      if (!response.ok) throw new Error('Network response was not ok.');
      const blob = await response.blob();
      saveAs(blob, `${fileName}.pdf`);
  } catch (error) {
      console.error('Failed to download PDF:', error);
  }
};

export async function downloadCombinedPDF(files) {
  const mergedPdf = await PDFDocument.create();
  
  // Fetch and load each PDF
  for (let file of files) {
    const existingPdfBytes = await fetch(file.value).then(res => res.arrayBuffer());
    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    
    // Copy pages from the current PDF to the merged PDF
    const pages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
    pages.forEach(page => mergedPdf.addPage(page));
  }

  // Serialize the combined PDF document to bytes
  const pdfBytes = await mergedPdf.save();

  // Create a blob and trigger a download
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'merged.pdf';
  link.click();
}


export function encodeUrl(url) {
  // Extract the base URL and filename
  const lastSlashIndex = url.lastIndexOf("/");
  const baseUrl = url.substring(0, lastSlashIndex + 1);
  const filename = url.substring(lastSlashIndex + 1);

  // Encode the filename to handle spaces and special characters
  const encodedFilename = encodeURIComponent(filename);

  // Concatenate the base URL with the encoded filename
  return baseUrl + encodedFilename;
}

export const checkIfEncrypted = async(encryptedPdfBytes) => {
  // Load the PDF as a byte array
  // const pdfPath = '/home/jarvis/Downloads/test.pdf';
  // Try to load the PDF document
  try {
      const pdfDoc = await PDFDocument.load(encryptedPdfBytes);
      // console.log("The PDF is not encrypted.");
      const decryptedPdfBytes = await pdfDoc.save();
      return false
  } catch (error) {
      if (error.message.includes('Invalid PDF structure')) {
          // console.log("The PDF is encrypted.");
      } else {
          // console.error("An error occurred:", error.message);
      }
      return true
  }
}
