import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export default function SelectSmall({file, value, onChange, disabled, options, width, type}) {
  
  return (
    <FormControl sx={{ m: 1, width: width }} size="small">
      {/* <InputLabel id="demo-select-small-label">Document Type</InputLabel> */}
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={value}
        // label="Age"
        disabled={disabled == "true"}
        onChange={(e)=>onChange(e.target.value, file)}
      >
        {/* <MenuItem value="">
          <em>None</em>
        </MenuItem> */}
        {
          options.map(option=>(
            <MenuItem value={option.value} key={option.value} className={`${type && 'py-4'}`}>
              {option.displayValue}
            </MenuItem>
          ))
        }
        {/* <MenuItem value='counterOffer'>Counter Offer</MenuItem>
        <MenuItem value='preApproval'>Pre-approval</MenuItem>
        <MenuItem value='disclosure'>Disclosure</MenuItem>
        <MenuItem value='addendum'>Addendum</MenuItem>
        <MenuItem value='others'>Others</MenuItem> */}
      </Select>
    </FormControl>
  );
}
