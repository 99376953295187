
const EditORShareButton = ({onClick, buttonName, Icon}) => {

    return (
        <button className='cursor-pointer bg-lightGray py-3 px-6' onClick={onClick}>
            <Icon className='inline mr-2'/> 
            {buttonName}
        </button>
    )
}

export default EditORShareButton