import Link from "next/link";
import React from "react";
import Container from "./container";

export default function Footer() {
  const links = [
    { name: "Home", href: "https://rebillion.ai/" },
    { name: "Terms of Service", href: "https://rebillion.ai/terms-of-service/" },
    { name: "Privacy Policy", href: "https://rebillion.ai/privacy-policy/" },
    { name: "Contact", href: "https://rebillion.ai/contact/" },
    { name: "Services", href: "https://rebillion.ai/services/" },
  ];

  return (
    <div className="bg-footerColor">
      <div className="relative px-8 md:px-28 leading-7">
        <Container>
          <div className="flex flex-col md:flex-row items-center justify-between md:items-start space-y-8 md:space-y-0">
            <div className="w-full md:w-1/3 px-4 md:px-10">
              <b>About Us</b>
              <p className="mt-4 md:mt-7 text-gray-700">
                ReBillion.ai redefines Transaction Coordination with AI technology and expert oversight. Complaint, Hassle-Free, On-Time & Cost-Effective transactions guaranteed.
              </p>
            </div>
            <div className="w-full md:w-1/3 px-4 md:px-10">
              <b>Useful Links</b>
              <div className="mt-4 md:mt-7 flex flex-col text-gray-700">
                {links.map((item, index) => (
                    <Link href={item.href} key={index} className={`w-full mb-2 text-gray-700 rounded-md hover:text-green ${index==0 && 'text-green'}`}>
                      {item.name}
                    </Link>
                ))}
              </div>
            </div>
            <div className="w-full md:w-1/3 px-4 md:px-10">
              <b>Contact Us</b>
              <div className="mt-4 md:mt-7 text-gray-700">
                <p>Back Office: CoSpaze Coworking, BVR Towers, Veerannapalya. Bengaluru 560045. India</p>
                <p>Tc@rebillion.ai</p>
                <p>+16502007853</p>
              </div>
            </div>
          </div>
        </Container>
        {/* Do not remove this */}
        {/* <Backlink /> */}
      </div>
      {/* <div className="w-full p-0.5 bg-gray-800"></div> */}
      <div className="pt-20 pb-3 flex flex-col md:flex-row items-center justify-center text-xs">
        Copyright © 2024 ReBillion
      </div>
    </div>
  );
}
