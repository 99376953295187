
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import DownIcon from '../../public/assets/downIconWhite.svg';
import { downloadAllAsZip, downloadPdf, downloadCombinedPDF } from './utils';

const MenuButton = (props) => {

    const {
        mergedPDFLink, 
        downloadAllFormsAsAZipFileLinksArray, 
        downloadSingleMeredFileFileLinksArray, 
        mergedPDFLinkFileName, 
        setLoading, 
        mergerPDFsIntoOne
    } = props

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const hanldeZipDownload = () => {
        setAnchorEl(null);
        downloadAllAsZip(downloadAllFormsAsAZipFileLinksArray, mergedPDFLinkFileName)
    }

    const hanldePdfDownload = async () => {
        setAnchorEl(null);
        setLoading(true)
        if(!mergedPDFLink){
            const pdfLink = await mergerPDFsIntoOne()
            downloadPdf(pdfLink, mergedPDFLinkFileName)
        }else{
            downloadPdf(mergedPDFLink, mergedPDFLinkFileName)
        }
        setLoading(false)
        // const file = arrayOfDocuments.filter(file=>file.displayValue=='Purchase Agreement')[0]
        // downloadPdf(file.value, file.displayValue)
    }

    return (
      <div
      >
        <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{
                backgroundColor: '#4F886E', // Always green background
                color: 'white', // Text color
                '&:hover': {
                    backgroundColor: '#4F886E', // Same green on hover
                },
                padding: '10px',
                textTransform: 'none', // Keeps the text in lowercase
                // Ensuring the styling is important even if nested in a complex component
                '&.MuiButton-root': {
                    backgroundColor: '#4F886E',
                    '&:hover': {
                        backgroundColor: '#4F886E'
                    }
                },
                fontWeight: "500",
                fontSize: '18px',
                paddingX: '16px',
                paddingY: '12px'
            }}
        >
            Download
            <DownIcon className='inline ml-4 mr-1' /> 
        </Button>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            sx={{
                '.MuiPaper-root': { // Styling the menu paper component
                    backgroundColor: '#white', // Light grey background
                    color: '#333', // Text color
                },
            }}
            disableScrollLock={true}
        >
            <MenuItem onClick={hanldeZipDownload}>Download all forms as a ZIP</MenuItem>
            <MenuItem onClick={hanldePdfDownload}>Download merged PDF</MenuItem>
        </Menu>
      </div>
    );
  };
  
  export default MenuButton;
  