import { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import localStorageSlim from 'localstorage-slim';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import USAStateList from '../../config/USA_State_List.json';
import MlsListing from './MlsListing'
import AgentIntakeForm from './AgentIntakeForm'
import AdditionalDocs from './AdditionalDocs'
import SelectDropdown from './SelectDropdown'
import ResponsePDF from './Response';
import Error from './Error';
import { apiCallWithAuth, fetchAuthToken } from './utils';
import { mergePDFs } from './utils';
import displayValues from '../../config/displayValues.json'
import LogoUpload from './LogoUpload';
import Loader from './Loader';
import { checkIfEncrypted, encodeUrl } from './utils';
import axios from 'axios';

const AgreementGeneration = () => {
  const [agentFormContainerExpanded, setAgentFormContainerExpanded] = useState(false)
  const [agentFormFile, setAgentFormFile] = useState(null);
  const [agentFormError, setAgentFormError] = useState('');

  const [mlsListingContainerExpanded, setMlsListingContainerExpanded] = useState(false)
  const [mlsListingFile, setMlsListingFile] = useState(null);
  const [mlsListingError, setMlsListingError] = useState('');

  
  const [logoContainerExpanded, setLogoContainerExpanded] = useState(false)
  const [logoFile, setLogoFile] = useState(null);
  const [logoFileError, setLogoFileError] = useState('');
  const [logoUrlLocal, setLogoUrlLocal] = useState(null);
  
  const [additionalFilesContainerExpanded, setAdditionalFilesContainerExpanded] = useState(false)
  const [additionalFiles, setAdditionalFiles] = useState([]);
  const [additionalFilesError, setAdditionalFilesError] = useState('');

  const [agreementDate, setAgreementDate] = useState(null);
  const [dateError, setDateError] = useState('');

  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [dropdownError, setDropdownError] = useState('');

  const [uploadLoading, setUploadLoading] = useState(false);
  const [agreementGenerationLoading, setAgreementGenerationLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [agreementOfferLink, setAgreementOfferLink] = useState('');
  const [requestId, setRequestId] = useState('');
  const [mergedPDFLink, setMergedPDFLink] = useState('')

  const [showMaximizedPopup, setShowMaximizedPopup] = useState(false);
  const [showMinimizedPopup, setShowMinimizedPopup] = useState(false);
  const [mergedPDFLinkFileName, setMergedPDFLinkFileName] = useState([])

  const [pdfViewerComponentFileLinksArray, setPdfViewerComponentFileLinksArray] = useState([])
  const [downloadAllFormsAsAZipFileLinksArray, setDownloadAllFormsAsAZipFileLinksArray] = useState([])
  const [downloadSingleMeredFileFileLinksArray, setDownloadSingleMeredFileFileLinksArray] = useState([])

  const onChangeSelectedState = (event) => {
    setSelectedState(event.target.value)
    localStorage.setItem('state',event.target.value)
  }

  const setOnlyOneContainerExpanded = (expandedName) => {
    // setAgentFormContainerExpanded(expandedName === 'agentForm');
    // setMlsListingContainerExpanded(expandedName === 'mlsListing');
    // setAdditionalFilesContainerExpanded(expandedName === 'additionalFiles');
    // setLogoContainerExpanded(expandedName === 'logo');
    if(expandedName=='agentForm'){
      setMlsListingContainerExpanded(false);
      setAdditionalFilesContainerExpanded(false);
      setLogoContainerExpanded(false);
    }else if(expandedName=='mlsListing'){
      setAgentFormContainerExpanded(false);
      setAdditionalFilesContainerExpanded(false);
      setLogoContainerExpanded(false);
    }else if(expandedName=='additionalFiles'){
      setAgentFormContainerExpanded(false);
      setMlsListingContainerExpanded(false);
      setLogoContainerExpanded(false);
    }else if(expandedName=='logo'){
      setAgentFormContainerExpanded(false);
      setMlsListingContainerExpanded(false);
      setAdditionalFilesContainerExpanded(false);
    }
  };
  

  useEffect(() => {
    // createPdfFile('Hey how are you ?')
    const initializeAuthToken = async () => {
      try {
        const token = localStorageSlim.get('auth', { decrypt: true });
        if (!token) {
          await fetchAuthToken();
        }
        setStates(USAStateList);
        let state =  localStorage.getItem("state")
        if (state) setSelectedState(state)
      } catch (error) {
        console.error('Error initializing auth token:', error);
      }
    };

    const initializeUserId = () => {
      let userId = localStorageSlim.get('userId', { decrypt: true });
      if (!userId) {
        userId = nanoid(20);
        localStorageSlim.set('userId', userId, { encrypt: true });
      }
    };

    initializeAuthToken();
    initializeUserId();
  }, []);

  const mergerPDFsIntoOne = async () => {
    const combinedPdfFile = await mergePDFs(downloadSingleMeredFileFileLinksArray)
    // console.log('combinedPdfFile: ',combinedPdfFile)
    const uploadResponsesNew = await Promise.all([uploadFile(combinedPdfFile)])
    const mergedLink = uploadResponsesNew[0]
    // console.log('mergedLink: ',mergedLink)
    
    setMergedPDFLink(mergedLink)
    return mergedLink
  };

  const handleUploadAndSummarize = async () => {
    if (!selectedState) return setDropdownError('Please select a state from dropdown!');
    if (!mlsListingFile) return setMlsListingError('Please upload the MLS Listing File!');
    if (!agentFormFile) return setAgentFormError('Please upload the Agent Form File!');
    if (agreementDate == null) return setDateError('Please select an Agreement Date!');
    if (additionalFiles.some(file => file.documentType === '')) {
        return setAdditionalFilesError("Please select a category for the files !");
    }

    setUploadLoading(true);
    setErrorMessage('');
    setAgentFormError('');
    setMlsListingError('');
    setAdditionalFilesError('');
    setDateError('');
    setDropdownError('');

    try {
        const mergedFile = await mergePDFs([agentFormFile, mlsListingFile]);

        const newRequestId = nanoid(20);
        setRequestId(newRequestId);

        const uploadResponses = await Promise.all([
            uploadFile(mergedFile),
            uploadFile(agentFormFile),
            uploadFile(mlsListingFile),
            uploadFile(logoFile),
            ...additionalFiles.map(file => uploadFile(file))
        ]);

        const filePaths = {
            mainFile: uploadResponses[0],
            agentFormFile: uploadResponses[1],
            mlsListingFile: uploadResponses[2],
            brokerLogoUrl: uploadResponses[3],
            additionalFiles: uploadResponses.slice(4)
        };

        const { localLinkGroups, s3LinkGroups} = await organizeFileLinks(additionalFiles, filePaths.additionalFiles, newRequestId);


        // console.log('localLinkGroups: ',localLinkGroups)
        // console.log('s3LinkGroups: ',s3LinkGroups)
        // console.log('localLinkGroups.counterOfferFiles: ',localLinkGroups.counterOfferFiles['0'])

        await apiCallWithAuth({
            method: 'POST',
            url: '/api/registerRequest',
            headers: {
                'Content-Type': 'application/json',
                'authToken': localStorageSlim.get('auth', { decrypt: true })
            },
            data: JSON.stringify({
                requestId: newRequestId,
                userId: localStorageSlim.get('userId', { decrypt: true }),
                state: selectedState,
            })
        });

        const responses = await Promise.all([
            apiCallWithAuth({
                method: 'POST',
                url: '/api/generateRequest',
                headers: {
                    'Content-Type': 'application/json',
                    'authToken': localStorageSlim.get('auth', { decrypt: true })
                },
                data: JSON.stringify({
                    fileLink: filePaths.mainFile,
                    brokerLogoUrl: filePaths.brokerLogoUrl,
                    mlsFileLinks: [filePaths.mlsListingFile],
                    agentFormFileLinks: [filePaths.agentFormFile],
                    agreementDate: agreementDate.toDate().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' }),
                    userId: localStorageSlim.get('userId', { decrypt: true }),
                    requestId: newRequestId,
                    state: selectedState,
                })
            }),
            apiCallWithAuth({
                method: 'POST',
                url: '/api/updateRequest',
                headers: {
                    'Content-Type': 'application/json',
                    'authToken': localStorageSlim.get('auth', { decrypt: true })
                },
                data: JSON.stringify({
                  userId: localStorageSlim.get('userId', { decrypt: true }),
                  requestId: newRequestId,
                  ...s3LinkGroups,
                })
            }),
            mergePDFs(localLinkGroups.counterOfferFiles),
            mergePDFs(localLinkGroups.preApprovalFiles),
            mergePDFs(localLinkGroups.addendumFiles),
            mergePDFs(localLinkGroups.disclosureFiles),
            mergePDFs(localLinkGroups.othersFiles),
        ]);

        const responseFromGenerateRequest = responses[0]
        
        const propertyAddress = responseFromGenerateRequest.data.propertyAddress
        setMergedPDFLinkFileName(`Offer for ${propertyAddress}`)


        const filePromises = responseFromGenerateRequest.data.fileLinks.map(async (fileObj) => {
          const response = await axios.get(fileObj.docLink, { responseType: 'arraybuffer' });
          // Creating a Blob from the ArrayBuffer
          const blob = new Blob([response.data], { type: 'application/pdf' });

          let filename = fileObj.docName

          if(propertyAddress){
            filename=`${filename} - ${propertyAddress}`
          }

          // Optionally convert the Blob to a File object
          const file = new File([blob], filename, { type: 'application/pdf' });
          return {
            value: file,
            displayValue: fileObj.docName,
          };
        });
    
        const downloadedFileLinks = await Promise.all(filePromises);
        // console.log('downloadedFileLinks: ',downloadedFileLinks); // Now contains the actual data objects
    

        const fileLinks =  downloadedFileLinks.map(file => file.value);
        
        // console.log('6')

        // console.log('7')

        const counterOfferFileMerged = responses[2]
        const preApprovalFileMerged = responses[3]
        const addendumFileMerged = responses[4]
        const disclosureFileMerged = responses[5]
        const othersFileMerged = responses[6]


        let pdfViewerComponentFileLinksArrayLocal = []
        let downloadAllFormsAsAZipFileLinksArrayLocal = []
        let downloadSingleMeredFileFileLinksArrayLocal = []

        // console.log('counterOfferFileMerged: ',counterOfferFileMerged)
        // console.log('preApprovalFileMerged: ',preApprovalFileMerged)
        // console.log('addendumFileMerged: ',addendumFileMerged)
        // console.log('disclosureFileMerged: ',disclosureFileMerged)
        // console.log('othersFileMerged: ',othersFileMerged)
        
          

        if (counterOfferFileMerged) {
          pdfViewerComponentFileLinksArrayLocal.push({
            'value': counterOfferFileMerged,
            'displayValue': displayValues.counterOffer.displayVlaue
          })
        }
        if (preApprovalFileMerged) {
          pdfViewerComponentFileLinksArrayLocal.push({
            'value': preApprovalFileMerged,
            'displayValue': displayValues.preApprovalFile.displayVlaue
          })
        }
        if (mlsListingFile) {
          pdfViewerComponentFileLinksArrayLocal.push({
            'value': mlsListingFile,
            'displayValue': displayValues.mlSFile.displayVlaue
          })
        }
        if (downloadedFileLinks) pdfViewerComponentFileLinksArrayLocal.push(...downloadedFileLinks)

        if (addendumFileMerged) {
          pdfViewerComponentFileLinksArrayLocal.push({
            'value': addendumFileMerged,
            'displayValue': displayValues.addendumFile.displayVlaue
          })
        }
        if (disclosureFileMerged) {
          pdfViewerComponentFileLinksArrayLocal.push({
            'value': disclosureFileMerged,
            'displayValue': displayValues.disclosureFile.displayVlaue
          }) 
        }
        if (othersFileMerged) {
          pdfViewerComponentFileLinksArrayLocal.push({
            'value': othersFileMerged,
            'displayValue': displayValues.othersFile.displayVlaue
          })
        }

        setUploadLoading(false); // stop the loading
        setShowMaximizedPopup(true); // shows the full screen popup
        setPdfViewerComponentFileLinksArray(pdfViewerComponentFileLinksArrayLocal) // pdf for viewing

        // console.log('localLinkGroups: ',localLinkGroups)
        // console.log('counterOfferFiles: ',localLinkGroups.counterOfferFiles)
        // console.log('preApprovalFiles: ',localLinkGroups.preApprovalFiles)
        // console.log('addendumFiles: ',localLinkGroups.addendumFiles)
        // console.log('disclosureFiles: ',localLinkGroups.disclosureFiles)
        // console.log('othersFiles: ',localLinkGroups.othersFiles)

        downloadAllFormsAsAZipFileLinksArrayLocal.push(
          ...localLinkGroups.counterOfferFiles,
          ...localLinkGroups.preApprovalFiles,
          mlsListingFile,
          ...fileLinks,
          ...localLinkGroups.addendumFiles,
          ...localLinkGroups.disclosureFiles,
          ...localLinkGroups.othersFiles,
        )

        setDownloadAllFormsAsAZipFileLinksArray(downloadAllFormsAsAZipFileLinksArrayLocal)

        downloadSingleMeredFileFileLinksArrayLocal.push(
          ...localLinkGroups.counterOfferFiles,
          ...localLinkGroups.preApprovalFiles,
          mlsListingFile,
          ...fileLinks,
          ...localLinkGroups.addendumFiles,
          ...localLinkGroups.disclosureFiles,
          ...localLinkGroups.othersFiles,
        )

        setDownloadSingleMeredFileFileLinksArray(downloadSingleMeredFileFileLinksArrayLocal)

        // console.log('pdfViewerComponentFileLinksArrayLocal: ',pdfViewerComponentFileLinksArrayLocal)
        // console.log('downloadAllFormsAsAZipFileLinksArrayLocal: ',downloadAllFormsAsAZipFileLinksArrayLocal)
        // console.log('downloadSingleMeredFileFileLinksArrayLocal: ',downloadSingleMeredFileFileLinksArrayLocal)

        // console.log('13')
        setAgreementOfferLink(mlsListingFile);
        // setArrayOfDocuments(allFileLinksArray)

        // console.log('14. Finished !')
    } catch (error) {
        setAgreementGenerationLoading(false);
        setUploadLoading(false);
        console.error('Failed to Generate Agreement:', error);
        setErrorMessage('Failed to write the offer, our system had a stomach upset it seems. In case its failing after multiple retries, please reach out to support@garvik.ai !');
    } finally {
        setAgreementGenerationLoading(false);
        setUploadLoading(false);
    }
};

async function uploadFile(file) {
    if (!file) return null
    const formData = new FormData();
    formData.append('file', file);
    const response = await apiCallWithAuth({
        method: 'POST',
        url: '/api/upload',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data.filePath;
}

async function organizeFileLinks(files, paths, requestId) {
  const localLinkGroups = {
      counterOfferFiles: [],
      preApprovalFiles: [],
      disclosureFiles: [],
      addendumFiles: [],
      othersFiles: []
  };

  const s3LinkGroups = {
      counterOfferFileLinks: [],
      preApprovalFileLinks: [],
      disclosureFileLinks: [],
      addendumFileLinks: [],
      othersFileLinks: []
  };

  for (let [index, file] of files.entries()) {
      // console.log('file: ', file);

      const isFileEncrypted = await checkIfEncrypted(file);  // Assuming this is a synchronous function

      // console.log('isFileEncrypted: ',isFileEncrypted)

      if (isFileEncrypted) {
          const apiResponse = await apiCallWithAuth({
              method: 'POST',
              url: '/api/decryptPdf',
              headers: {
                  'Content-Type': 'application/json',
                  'authToken': localStorageSlim.get('auth', { decrypt: true })
              },
              data: JSON.stringify({
                  userId: localStorageSlim.get('userId', { decrypt: true }),
                  requestId: requestId,
                  fileLink: paths[index]
              })
          });

          const decryptedLink = encodeUrl(apiResponse.data.fileLink);
          const response = await axios.get(decryptedLink, { responseType: 'arraybuffer' });
          const blob = new Blob([response.data], { type: 'application/pdf' });
          // Optionally convert the Blob to a File object
          const newFile = new File([blob], file.name, { type: 'application/pdf' });
          newFile.documentType = file.documentType
          file = newFile
          paths[index] = decryptedLink;
      }

      switch (file.documentType) {
          case 'counterOffer':
              s3LinkGroups.counterOfferFileLinks.push(paths[index]);
              localLinkGroups.counterOfferFiles.push(file);
              break;
          case 'preApproval':
              s3LinkGroups.preApprovalFileLinks.push(paths[index]);
              localLinkGroups.preApprovalFiles.push(file);
              break;
          case 'disclosure':
              s3LinkGroups.disclosureFileLinks.push(paths[index]);
              localLinkGroups.disclosureFiles.push(file);
              break;
          case 'addendum':
              s3LinkGroups.addendumFileLinks.push(paths[index]);
              localLinkGroups.addendumFiles.push(file);
              break;
          case 'others':
              s3LinkGroups.othersFileLinks.push(paths[index]);
              localLinkGroups.othersFiles.push(file);
              break;
      }
  }

  return {
      localLinkGroups,
      s3LinkGroups
  };
}



  const resetExperience = () => {
    setAgentFormFile(null);
    setMlsListingFile(null);
    setAgreementOfferLink('');
    setAgreementDate(null);
    setDownloadAllFormsAsAZipFileLinksArray([])
    setDownloadSingleMeredFileFileLinksArray([])
    setPdfViewerComponentFileLinksArray([])
    setMergedPDFLink('')
    setAdditionalFiles([])
    setLogoFile('')
    setRequestId('')
    setLogoUrlLocal('')
    setMergedPDFLinkFileName('')

    setAgentFormError('');
    setMlsListingError('');
    setErrorMessage('');
    setAdditionalFilesError('')
    setDateError('')
    setDropdownError('')
    setLogoFileError('')
  };


  return (
    <div className="w-full max-w-4xl mx-auto rounded px-8 pt-6 pb-8 mb-4 dark:bg-gray-800 flex flex-col items-center">

      <div className="w-full md:w-2/3">
          <SelectDropdown value={selectedState} handleChange={onChangeSelectedState} statesList={USAStateList} />
      </div>
      <Error error={dropdownError} />

      <MlsListing
        file={mlsListingFile}
        setFile={setMlsListingFile}
        error={mlsListingError}
        setError={setMlsListingError}
        title={"Property Details- MLS Listing"}
        containerExpanded={mlsListingContainerExpanded}
        setContainerExpanded={setMlsListingContainerExpanded}
        setOnlyOneContainerExpanded={setOnlyOneContainerExpanded}
      />

      <AgentIntakeForm
        file={agentFormFile}
        setFile={setAgentFormFile}
        error={agentFormError}
        setError={setAgentFormError}
        title={"Offer Details"}
        containerExpanded={agentFormContainerExpanded}
        setContainerExpanded={setAgentFormContainerExpanded}
        setOnlyOneContainerExpanded={setOnlyOneContainerExpanded}
      />

      <div className="w-full md:w-2/3 text-center mt-6">
        <div className="flex flex-col items-center justify-center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={agreementDate}
              onChange={(date) => setAgreementDate(date)}
              label="Date of Offer"
              className="w-full" 
              sx={{
                '& .MuiInputBase-input': {
                  color: '#49454F', // Text color
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#79747E', // Default border color
                    borderRadius: '8px'
                  },
                },
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <Error error={dateError} />

      <AdditionalDocs
        error={additionalFilesError}
        setError={setAdditionalFilesError}
        files={additionalFiles}
        setFiles={setAdditionalFiles}
        title={"Additional Documents"}
        containerExpanded={additionalFilesContainerExpanded}
        setContainerExpanded={setAdditionalFilesContainerExpanded}
        setOnlyOneContainerExpanded={setOnlyOneContainerExpanded}
      />

      <LogoUpload
        file={logoFile}
        setFile={setLogoFile}
        error={logoFileError}
        setError={setLogoFileError}
        logoUrlLocal={logoUrlLocal}
        setLogoUrlLocal={setLogoUrlLocal}
        title={"Broker Logo (optional)"}
        containerExpanded={logoContainerExpanded}
        setContainerExpanded={setLogoContainerExpanded}
        setOnlyOneContainerExpanded={setOnlyOneContainerExpanded}
      />

      {(uploadLoading || agreementGenerationLoading) && (
        <Loader loadingMessage="Generating Offer..."/>
      )}

      <div className='mt-12'>
          {
            errorMessage && 
              <div className='mb-5'>
                <Error error={errorMessage} />
              </div>
          }
          {!agreementOfferLink && !uploadLoading && !agreementGenerationLoading && (
            <button
              onClick={handleUploadAndSummarize}
              disabled={uploadLoading}
              className="flex items-center justify-center mx-auto text-base text-white font-semibold py-3 px-6 rounded-md text-lg bg-green hover:bg-green"
            >
              Make an Offer
            </button>
          )}
      </div>


      {agreementOfferLink && !uploadLoading && !agreementGenerationLoading && (
        <ResponsePDF
          showMaximizedPopup={showMaximizedPopup}
          setShowMaximizedPopup={setShowMaximizedPopup}
          showMinimizedPopup={showMinimizedPopup}
          setShowMinimizedPopup={setShowMinimizedPopup}
          requestId={requestId}
          agreementOfferLink={agreementOfferLink}
          setAgreementOfferLink={setAgreementOfferLink}
          mergedPDFLink={mergedPDFLink}
          mergedPDFLinkFileName={mergedPDFLinkFileName}
          mergerPDFsIntoOne={mergerPDFsIntoOne}
          downloadAllFormsAsAZipFileLinksArray={downloadAllFormsAsAZipFileLinksArray}
          downloadSingleMeredFileFileLinksArray={downloadSingleMeredFileFileLinksArray}
          pdfViewerComponentFileLinksArray={pdfViewerComponentFileLinksArray}
          resetExperience= {resetExperience}
        />
      )}

      <div className="text-center text-gray mt-16 dark:text-gray-400">
        <p>Files stay private. Automatically deleted after 2 hours.</p>
        <p>Free service for documents up to 10 pages or 5 MB and 3 tasks per hour</p>
        <p className='mt-4'>
          <a href="https://rebillion.ai/terms-of-service/" className="underline">Terms of Use</a> and <a href="https://rebillion.ai/privacy-policy/" className="underline">Privacy Policy</a>
        </p>
      </div>

    </div>
  );
};

export default AgreementGeneration;
