import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { useState, useMemo, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

export default function PDFViewer(props) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const options = useMemo(() => ({
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
  }), []);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages ?? 0);
    setError(null); // Clear any previous errors
  }

  function onPageLoadSuccess() {
    setLoading(false);
  }

  function handlePageChange(newPageNumber) {
    if (newPageNumber < 1 || newPageNumber > numPages) return;
    setLoading(true);
    setPageNumber(newPageNumber);
  }

  const goToNextPage = () => {
    handlePageChange(pageNumber + 1);
  };

  const goToPreviousPage = () => {
    handlePageChange(pageNumber - 1);
  };

  useEffect(() => {
    // Clear the error when the file prop changes
    setError(null);
    setPageNumber(1)
  }, [props.file]);

  return (
    <div className="flex flex-col items-center justify-center w-full">
      {/* <Nav pageNumber={pageNumber} numPages={numPages} /> */}
      <div className="flex flex-row items-center justify-center w-full">
        <button
          onClick={goToPreviousPage}
          disabled={pageNumber <= 1 || loading}
          className="absolute left-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400"
          style={{ top: '50%' }}
        >
          <ChevronLeftIcon className="h-10 w-10" aria-hidden="true" />
        </button>
        <div className="w-full flex justify-center">
          <Document
            file={props.file}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(error) => setError(error)}
            options={options}
            className="w-full flex justify-center"
          >
            <Page
              key={pageNumber}
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              onLoadSuccess={onPageLoadSuccess}
              onRenderError={(error) => setError(error)}
              className="mx-auto"
              // height={ 00}
            />
          </Document>
        </div>
        <button
          onClick={goToNextPage}
          disabled={pageNumber >= numPages || loading}
          className="absolute right-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400"
          style={{ top: '50%' }}
        >
          <ChevronRightIcon className="h-10 w-10" aria-hidden="true" />
        </button>
      </div>
      {/* <div className="bg-gray-900 text-white rounded-md px-3 py-1 text-sm font-medium mt-10">
        <span>{pageNumber}</span>
        <span className="text-gray-400"> / {numPages}</span>
      </div> */}
      <div className="text-lightColor4 rounded-md px-3 py-1 text-sm font-medium mt-10">
        {pageNumber} of {numPages} pages
      </div>
      {loading && (
        <div className="flex justify-center items-center w-full absolute top-0 bottom-0 left-0 right-0 bg-white bg-opacity-75">
          <svg className="animate-spin h-10 w-10 text-gray-800" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
          </svg>
        </div>
      )}
      {error && (
        <div className="text-red-600 mt-4">
          <p>Error loading document: {error.message}</p>
        </div>
      )}
    </div>
  );
}

function Nav({ pageNumber, numPages }) {
  return (
    <nav className="bg-black w-full flex items-center justify-center h-8">
      <div className="mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-8 items-center justify-between">
          <div className="flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="bg-gray-900 text-white rounded-md px-3 py-1 text-sm font-medium">
              <span>{pageNumber}</span>
              <span className="text-gray-400"> / {numPages}</span>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
