
const Error = ({error}) => {

    return (
        <>
            {
                error && (
                    <div className="mt-2 ml-2 text-base font-semibold text-red-500">
                        {error}
                    </div>
            )}
        </>
    )
}


export default Error