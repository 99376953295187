const DragAndDrop = ({placeholderText ,handleDrop, files, handleFileChange, handleDragOver, multiple, accept, setError}) => {

  return (
    <div
        onDrop={(e) => handleDrop(e)}
        onDragOver={handleDragOver}
    >
    <label htmlFor="file" className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
        <div className="flex flex-col items-center justify-center pt-5 pb-6">
        <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
        </svg>
        <p className="mb-2 text-sm text-gray ">
            {   
                multiple == "true" && files.length > 0?
                    <span className="font-semibold text-gray-800">Add more document</span>
                :
                multiple == "false" && files?
                    <span className="font-semibold text-gray-800">Re-upload document</span>
                :
                <>
                    <span className="font-semibold text-gray-800">Click to upload</span> or <span className='font-semibold text-gray-800'>drag and drop</span>
                </>
            }
        </p>
        <div className="text-xs text-gray font-normal items-center text-center lg:px-20 md:px-2">{placeholderText}</div>
        </div>
        <input
            id="file"
            type="file"
            className="hidden"
            accept={accept}
            multiple={multiple == "true"}
            onChange={(e) => {handleFileChange(e); setError && setError('')}}
        />
    </label>
    </div>
  );
};

export default DragAndDrop;
