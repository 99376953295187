
import UpIcon from '../../public/assets/upIcon.svg';
import DownIcon from '../../public/assets/downIcon.svg';
import DocumentIcon from '../../public/assets/file_icon.svg';
import DeleteIcon from '../../public/assets/delete_icon.svg';
import DragAndDrop from './DragAndDrop';  
import { handleFileChange, handleDragOver, handleDrop } from './utils';
import { useState } from 'react';
import Image from 'next/image';
import CrossIcon from '../../public/assets/cross.svg';

const LogoUpload = (props) => {

  // const [containerExpanded, setContainerExpanded] = useState(false);

  const {
    file, 
    setFile, 
    error, 
    setError, 
    title, 
    logoUrlLocal, 
    setLogoUrlLocal, 
    containerExpanded, 
    setContainerExpanded,
    setOnlyOneContainerExpanded
  } = props

  const onDelete = () => {
      setFile(null)
      // setContainerExpanded(true)
      // setOnlyOneContainerExpanded('logo')
      setLogoUrlLocal(null)
  }

  const handleFileChangeCustom = (e) =>{
    const file = e.target.files[0]
    onChangeLogoFile(file)
  }

  const handleDropCustom = (e) =>{
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    onChangeLogoFile(file)
  }

  const onChangeLogoFile = (file) => {
    // Check if the file size is greater than 1 MB
    if (file.size > 1024 * 1024) { // 1024 * 1024 bytes = 1 MB
        setErrorMessage('File size should not exceed 1 MB');
        return; // Stop the function if the file is too large
    }
    if (file && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/webp")) {
      // handleFileChange(e.target.files[0], setFile, 7, setError, setContainerExpanded)
      setFile(file);  // Update the state with the new file
      setContainerExpanded(false)
      const reader = new FileReader();  // Create a file reader
      reader.onloadend = () => {
        setLogoUrlLocal(reader.result);  // Set the image URL once reading is done
      };
      reader.readAsDataURL(file);  // Start reading the file as Data URL
  } else {
      setError('Please select a JPEG or PNG or WEBP image.');
  }
  }

  return (
    <div className="w-full md:w-2/3">
        <div className={`border rounded-lg mt-6 border-borderColor `}>
          <div
            className={`flex justify-between items-center cursor-pointer ${containerExpanded ? ' bg-green' : ''} p-4 rounded-t-lg`}
            onClick={() =>  {setContainerExpanded(!containerExpanded);setOnlyOneContainerExpanded('logo')}}
          >
            <h2 className={`font-normal text-base ${containerExpanded ? ' text-white' : 'text-lightColor4'}`}>
                {title}
            </h2>
            {containerExpanded ? <UpIcon /> : <DownIcon />}
          </div>
          {containerExpanded && (   
            <div className='flex flex-col items-center w-full'>
              <div className="w-full p-5">
                <DragAndDrop 
                  files={file} 
                  handleDragOver={handleDragOver} 
                  handleDrop={handleDropCustom} 
                  handleFileChange={handleFileChangeCustom} 
                  setError={setError}
                  placeholderText="Accepted formats PNG or JPEG or WEBP. Recommended size 150x150 pixels"
                  multiple={"false"}
                  accept="image/png, image/jpeg, image/webp"
                />
              </div>
            </div>
          )}
        </div>

        {logoUrlLocal && (
              <div className='flex flex-row mt-4'>
                <Image 
                  src={logoUrlLocal} 
                  alt="Uploaded" 
                  // style={{ maxWidth: '100%', height: 'auto' }} 
                  className="mt-5"
                  width="80"  // Set the width you want
                  height="80" // Maintain aspect ratio or set as needed
                />
                <CrossIcon
                  className='cursor-pointer mt-1' 
                  onClick={()=>{onDelete();setError('')} }
                />
              </div>
          )}
        {error && (
          <div className="mt-2 ml-2 text-base font-semibold text-red-500">
            {error}
          </div>
        )}
    </div>
  );
};

export default LogoUpload;
